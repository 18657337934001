import React from 'react';
import '../styles/end.popup.style.css';

type Props = {
  onCancelButtonClicked: () => void;
  onConfirmButtonClicked: () => void;
};

const StopPopup = ({
  onCancelButtonClicked,
  onConfirmButtonClicked,
}: Props) => {
  return (
    <div className='end-popup-root'>
      <div className='title-area'>
        <div className='title-text'>정지</div>
      </div>
      <div className='content-area'>
        <div className='text red'>정말 정지하시겠습니까?</div>
        <div className='text'>모든 시간이 정지합니다</div>
      </div>
      <div className='button-area'>
        <button className='cancel-button' onClick={onCancelButtonClicked}>
          취소
        </button>
        <button className='confirm-button' onClick={onConfirmButtonClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default StopPopup;
