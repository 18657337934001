import { SystemState } from '@store/system/reducer';
import {
  BoardItemStateType,
  Coordinate,
  SendTimeType,
} from '@typedef/Board/board.types';
import { UserType } from '@typedef/common/user.types';
import React from 'react';
import UserItemContainer from './containers/UserItemContainer';
import Sortable from './hoc/Sortable';
import './styles/board.style.css';

type Props = {
  onEntireClicked: () => void;
  onTimeChanged: (time: keyof SendTimeType, input: string) => void;
  onTimeClicked: (time: number) => void;
  onResetClicked: () => void;
  onSendClicked: () => void;
  onBoardCheckClicked: (index: number) => void;
  onBoardLockCliked: (index: number) => void;
  onSignToggleClicked: () => void;
  onEndButtonClicked: () => void;
  onStartButtonClicked: () => void;
  onResetButtonClicked: () => void;
  sortedIndex: number[];
  userList: UserType[];
  sendTime: SendTimeType;
  stateList: BoardItemStateType[];
  isPlus: boolean;
  systemState: SystemState;
  boardRef: React.RefObject<HTMLDivElement>;
};

const Board = ({
  onEntireClicked,
  onTimeChanged,
  onTimeClicked,
  onResetClicked,
  onSendClicked,
  onBoardCheckClicked,
  onBoardLockCliked,
  onSignToggleClicked,
  onEndButtonClicked,
  onStartButtonClicked,
  onResetButtonClicked,
  sortedIndex,
  userList,
  sendTime,
  stateList,
  isPlus,
  systemState,
  boardRef,
}: Props) => {
  return (
    <div className='board-root'>
      <div className='control-section'>
        <div className='button-wrapper'>
          {systemState === 'shutdown' && (
            <button className='start-button' onClick={onStartButtonClicked}>
              시작
            </button>
          )}
          {systemState === 'processing' && (
            <>
              <button className='end-button' onClick={onEndButtonClicked}>
                정지
              </button>
              <button className='reset-button' onClick={onResetButtonClicked}>
                초기화
              </button>
            </>
          )}
        </div>
        <div className='time-sender'>
          <div className='select-section'>
            <div className='time-select'>
              <button className='time-button' onClick={onEntireClicked}>
                전체선택
              </button>
              <button className='time-button' onClick={() => onTimeClicked(10)}>
                +10분
              </button>
              <button className='time-button' onClick={() => onTimeClicked(30)}>
                +30분
              </button>
              <button className='time-button' onClick={() => onTimeClicked(60)}>
                +1시간
              </button>
              <button
                className='time-button'
                onClick={() => onTimeClicked(1440)}>
                +24시간
              </button>
            </div>
            <div className='time-select'>
              <button className='time-button' onClick={onResetClicked}>
                선택초기화
              </button>
              <button
                className='time-button'
                onClick={() => onTimeClicked(-10)}>
                -10분
              </button>
              <button
                className='time-button'
                onClick={() => onTimeClicked(-30)}>
                -30분
              </button>
              <button
                className='time-button'
                onClick={() => onTimeClicked(-60)}>
                -1시간
              </button>
              <button
                className='time-button'
                onClick={() => onTimeClicked(-1440)}>
                -24시간
              </button>
            </div>
          </div>
          <div className='input-section'>
            <div className='row'>
              <button className={'sign-toggle'} onClick={onSignToggleClicked}>
                <div className='sign'>
                  <span>+</span>
                </div>
                <div className='sign'>
                  <span>-</span>
                </div>
                <div className={`switch ${isPlus ? '' : 'minus'}`}></div>
              </button>
              <input
                value={Math.abs(sendTime.hour)}
                className='input'
                onChange={(e) => onTimeChanged('hour', e.target.value)}
              />
              <span className='text'>시간</span>
              <input
                value={Math.abs(sendTime.minute)}
                className='input'
                onChange={(e) => onTimeChanged('minute', e.target.value)}
              />
              <span className='text'>분</span>
              <input
                value={Math.abs(sendTime.second)}
                className='input'
                onChange={(e) => onTimeChanged('second', e.target.value)}
              />
              <span className='text'>초</span>
            </div>
            <div className='row'>
              <button className='send-button' onClick={onSendClicked}>
                적용
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='user-board-container' ref={boardRef}>
        {userList.length !== 0 &&
          sortedIndex.map((index, i) => (
            <Sortable location={i} index={index} key={userList[index].id}>
              <UserItemContainer
                user={userList[index]}
                state={stateList[index]}
                index={index}
                onBoardCheckClicked={onBoardCheckClicked}
                onBoardLockCliked={onBoardLockCliked}
              />
            </Sortable>
          ))}
      </div>
    </div>
  );
};

export default Board;
