import React, { useCallback, useEffect, useMemo } from 'react';
import CPaging from '../CPaging';

type Props = {
  maxPage: number;
  page: number;
  onPageChanged: (page: number) => void;
};

const CPagingContainer = ({ maxPage, page, onPageChanged }: Props) => {
  const paginationIdx = useMemo(() => Math.floor(page / 5), [page]);

  const endPage = useMemo(
    () => Math.min(maxPage, paginationIdx * 5 + 4),
    [maxPage, paginationIdx],
  );

  const pageList = useMemo(
    () =>
      Array.from(new Array(endPage - paginationIdx * 5 + 1).keys()).map(
        (item) => item + paginationIdx * 5,
      ),
    [paginationIdx, endPage, page],
  );

  const onPrevPageClicked = useCallback(() => {
    onPageChanged(Math.max(0, page - 1));
  }, [page]);

  const onPageClicked = useCallback(
    (page: number) => {
      onPageChanged(page);
    },
    [onPageChanged],
  );

  const onNextPageClicked = useCallback(() => {
    onPageChanged(Math.min(page + 1, maxPage));
  }, [onPageChanged, page, maxPage]);

  return (
    <CPaging
      page={page}
      maxPage={maxPage}
      pageList={pageList}
      onPrevPageClicked={onPrevPageClicked}
      onPageClicked={onPageClicked}
      onNextPageClicked={onNextPageClicked}
    />
  );
};

export default CPagingContainer;
