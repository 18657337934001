import usePopup from '@hooks/components/usePopup';
import useSystem from '@hooks/components/useSystem';
import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecurePost } from '@lib/api/api';
import React, { useCallback } from 'react';
import EndPopup from '../components/EndPopup';

type Props = {
  getUserList: () => void;
};

const EndPopupContainer = ({ getUserList }: Props) => {
  const { hidePopup } = usePopup();
  const { getAccessToken } = useToken();
  const { __shutdownSystem } = useSystem();

  const onCancelButtonClicked = useCallback(() => {
    hidePopup();
  }, [hidePopup]);

  const onConfirmButtonClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePost(
      apiRoute.dashboard.end,
      {},
      {},
      getAccessToken() || '',
    );

    if (status !== 201) {
      alert(data);
      return;
    }

    __shutdownSystem();
    hidePopup();
    getUserList();
  }, [hidePopup, getAccessToken, __shutdownSystem, getUserList]);

  return (
    <EndPopup
      onCancelButtonClicked={onCancelButtonClicked}
      onConfirmButtonClicked={onConfirmButtonClicked}
    />
  );
};

export default EndPopupContainer;
