import React from 'react';
import '../styles/name.popup.style.css';

type Props = {
  name: string;
  onNameChange: (input: React.ChangeEvent<HTMLInputElement>) => void;
  onCancelButtonClicked: () => void;
  onConfirmButtonClicked: () => void;
};

const NameChangePopup = ({
  name,
  onNameChange,
  onCancelButtonClicked,
  onConfirmButtonClicked,
}: Props) => {
  return (
    <div className='name-popup-root'>
      <div className='title-area'>
        <div className='title-text'>사용자 이름</div>
      </div>
      <div className='content-area'>
        <div className='text'>사용자 이름을 수정하시겠습니까?</div>
        <input
          type='text'
          className='name-input'
          placeholder='새로운 이름을 입력해주세요'
          value={name}
          onChange={onNameChange}
        />
      </div>
      <div className='button-area'>
        <button className='cancel-button' onClick={onCancelButtonClicked}>
          취소
        </button>
        <button className='confirm-button' onClick={onConfirmButtonClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default NameChangePopup;
