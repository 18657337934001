import { RootState } from '@store/rootReducer';
import { endSystem, startSystem } from '@store/system/actions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export default function useSystem() {
  const dispatch = useDispatch();
  const systemState = useSelector((state: RootState) => state.systemReducer);

  const __shutdownSystem = useCallback(() => {
    dispatch(endSystem());
  }, [dispatch, endSystem]);

  const __startSystem = useCallback(() => {
    dispatch(startSystem());
  }, [dispatch, startSystem]);

  return {
    systemState,
    __shutdownSystem,
    __startSystem,
  };
}
