import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecurePatch } from '@lib/api/api';
import React, { useCallback, useMemo, useState } from 'react';
import LiveTime from '../LiveTime';

const LiveTimeContainer = () => {
  const { getAccessToken } = useToken();

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [second, setSecond] = useState(0);
  const unixTime = useMemo(() => {
    const d = new Date(date);
    const timeArray = time.split(':');
    d.setHours(parseInt(timeArray[0]), parseInt(timeArray[1]), second);

    return parseInt(d.getTime() / 1000 + '');
  }, [date, time, second]);

  const onDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  }, []);

  const onTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  }, []);

  const onSecondChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSecond(parseInt(e.target.value));
    },
    [],
  );

  const onResetButtonClicked = useCallback(() => {
    setDate('');
    setTime('');
    setSecond(0);
  }, []);

  const onSettingButtonClicked = useCallback(async () => {
    if (date === '') {
      alert('날짜를 설정해주세요');
      return;
    }
    if (time === '') {
      alert('시각을 설정해주세요');
      return;
    }

    const {
      data,
      config: { status },
    } = await requestSecurePatch(
      apiRoute.livetime.updateLivetime + `?time=${unixTime}`,
      {},
      {},
      getAccessToken() || '',
    );

    if (status !== 200) {
      return;
    }

    alert('생명시간이 수정되었습니다');
  }, [unixTime, date, time]);

  return (
    <LiveTime
      date={date}
      time={time}
      second={second}
      onDateChange={onDateChange}
      onTimeChange={onTimeChange}
      onSecondChange={onSecondChange}
      onResetButtonClicked={onResetButtonClicked}
      onSettingButtonClicked={onSettingButtonClicked}
    />
  );
};

export default LiveTimeContainer;
