import { images } from '@assets/images';
import { BankItemStateType } from '@typedef/Bank/bank.types';
import React from 'react';

type Props = {
  state: BankItemStateType;
  user: string;
  time: string;
  onCheckClicked: () => void;
};

const UserItem = ({ state, user, time, onCheckClicked }: Props) => {
  return (
    <div className={`user-item-root ${state}`}>
      <div className='header'>
        <button className='check-box' onClick={onCheckClicked}>
          {state === 'checked' && (
            <img src={images.check.selected} alt='ic_selected' />
          )}
          {state === 'unchecked' && (
            <img src={images.check.unselected} alt='ic_unselected' />
          )}
        </button>
      </div>
      <div className='content'>
        <div className='username'>{user}</div>
        <div className='red-time'>{time}</div>
      </div>
    </div>
  );
};

export default UserItem;
