import { images } from '@assets/images';
import { LoginFormType } from '@typedef/Login/login.types';
import React from 'react';
import './styles/login.styles.css';

type Props = {
  loginForm: LoginFormType;
  isVisible: boolean;
  onLoginFormChanged: (key: keyof LoginFormType, value: string) => void;
  onDeleteInputClicked: () => void;
  onPasswordToggleClicked: () => void;
  onLoginBtnClicked: () => void;
};

const Login = ({
  loginForm,
  isVisible,
  onLoginFormChanged,
  onDeleteInputClicked,
  onPasswordToggleClicked,
  onLoginBtnClicked,
}: Props) => {
  return (
    <div className='login-root'>
      <div className='login-container'>
        <h1>{'로그인'}</h1>
        <form>
          <div className='input-container id'>
            <label htmlFor='id-input' hidden>
              {'아이디'}
            </label>
            <input
              type='text'
              id='id-input'
              placeholder='아이디'
              value={loginForm.id}
              onChange={(e) => onLoginFormChanged('id', e.target.value)}
            />
            {loginForm.id.length > 0 && (
              <div className='btn' onClick={onDeleteInputClicked}>
                <img
                  src={images.deleteBtn}
                  alt='delete-button'
                  className='delete-btn'
                />
              </div>
            )}
          </div>
          <div className='input-container pw'>
            <label htmlFor='pw-input' hidden>
              {'비밀번호'}
            </label>
            <input
              type={isVisible ? 'text' : 'password'}
              placeholder='비밀번호'
              id='pw-input'
              value={loginForm.pw}
              onChange={(e) => onLoginFormChanged('pw', e.target.value)}
            />
            {loginForm.pw.length > 0 && (
              <div className='btn' onClick={onPasswordToggleClicked}>
                <img
                  src={isVisible ? images.eye : images.eyeDisable}
                  alt='delete-button'
                  className='delete-btn'
                />
              </div>
            )}
          </div>
          <button
            className='login-btn'
            onClick={(e) => {
              e.preventDefault();
              onLoginBtnClicked();
            }}>
            {'로그인'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
