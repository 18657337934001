import React from 'react';
import './styles/cpaging.styles.css';
import { images } from '@assets/images';

type Props = {
  page: number;
  maxPage: number;
  pageList: number[];
  onPrevPageClicked: () => void;
  onPageClicked: (page: number) => void;
  onNextPageClicked: () => void;
};

const CPaging = ({
  page,
  maxPage,
  pageList,
  onPrevPageClicked,
  onPageClicked,
  onNextPageClicked,
}: Props) => {
  return (
    <div className={'cpaging-root'}>
      <button onClick={onPrevPageClicked} className={'move-btn'}>
        <img
          src={page !== 0 ? images.cpaging.prev : images.cpaging.prevDisabled}
          alt='이전버튼'
        />
      </button>
      {pageList.map((n, i) => (
        <button
          onClick={() => onPageClicked(n)}
          key={i}
          className={`${'number-btn'} ${page === n && 'current'}`}>
          {n + 1}
        </button>
      ))}
      <button onClick={onNextPageClicked} className={'move-btn'}>
        <img
          src={
            page !== maxPage ? images.cpaging.next : images.cpaging.nextDisabled
          }
          alt='다음버튼'
        />
      </button>
    </div>
  );
};

export default CPaging;
