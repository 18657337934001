import React from 'react';
import './styles/header.styles.css';

type Props = {
  currentTab: string;
  onLogoutClicked: () => void;
};

const Header = ({ currentTab, onLogoutClicked }: Props) => {
  return (
    <header>
      {currentTab === '/' && <h1>{'현황판'}</h1>}
      {currentTab === '/send' && <h1>{'전송조회'}</h1>}
      {currentTab === '/buy' && <h1>{'구매조회'}</h1>}
      {currentTab === '/total' && <h1>{'총 사용 시간'}</h1>}
      {currentTab === '/bank' && <h1>{'은행'}</h1>}
      {currentTab === '/livetime' && <h1>{'생명시간'}</h1>}
      <button onClick={onLogoutClicked}>{'로그아웃'}</button>
    </header>
  );
};

export default Header;
