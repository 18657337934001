import { images } from "@assets/images";
import { GNBTabTypes } from "@typedef/GNB/gnb.types";
import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GNB from "../GNB";

const GNBContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [gnbTab, setGnbTab] = useState<GNBTabTypes[]>([
    {
      label: "현황판",
      path: "/",
      icon: { active: images.gnb.gnb01atv, inactive: images.gnb.gnb01Inatv },
    },
    {
      label: "전송조회",
      path: "/send",
      icon: { active: images.gnb.gnb02atv, inactive: images.gnb.gnb02Inatv },
    },
    {
      label: "구매조회",
      path: "/buy",
      icon: { active: images.gnb.gnb03atv, inactive: images.gnb.gnb03Inatv },
    },
    {
      label: "총 사용 시간",
      path: "/total",
      icon: { active: images.gnb.gnb04atv, inactive: images.gnb.gnb04Inatv },
    },
    {
      label: "은행",
      path: "/bank",
      icon: { active: images.gnb.gnb05atv, inactive: images.gnb.gnb05Inatv },
    },
    {
      label: "생명시각",
      path: "/livetime",
      icon: { active: images.gnb.gnb06atv, inactive: images.gnb.gnb06Inatv },
    },
  ]);

  const [selectedTab, setSelectedTab] = useState<string>("/");

  const onTabClicked = useCallback((path: string) => {
    setSelectedTab(path);
    navigate(path);
  }, []);

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location]);

  return (
    <GNB
      gnbTab={gnbTab}
      selectedTab={selectedTab}
      onTabClicked={onTabClicked}
    />
  );
};

export default GNBContainer;
