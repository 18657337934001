import React from 'react';
import '../styles/end.popup.style.css';

type Props = {
  onCancelButtonClicked: () => void;
  onConfirmButtonClicked: () => void;
};

const EndPopup = ({ onCancelButtonClicked, onConfirmButtonClicked }: Props) => {
  return (
    <div className='end-popup-root'>
      <div className='title-area'>
        <div className='title-text'>초기화</div>
      </div>
      <div className='content-area'>
        <div className='text red'>정말 초기화하시겠습니까?</div>
        <div className='text'>
          빨간 시간, 은행, 구매 기록 등 모든 데이터를 초기화합니다
        </div>
      </div>
      <div className='button-area'>
        <button className='cancel-button' onClick={onCancelButtonClicked}>
          취소
        </button>
        <button className='confirm-button' onClick={onConfirmButtonClicked}>
          확인
        </button>
      </div>
    </div>
  );
};

export default EndPopup;
