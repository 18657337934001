import {
  UPDATE_INDEX,
  UPDATE_SELECTION,
  UPDATE_HOVER,
} from './modules/actionType';

export const updateIndex = (payload: number[]) => {
  return {
    type: UPDATE_INDEX,
    payload,
  };
};

export const updateSelection = (payload: number | null) => {
  return {
    type: UPDATE_SELECTION,
    payload,
  };
};

export const updateHover = (payload: number | null) => {
  return {
    type: UPDATE_HOVER,
    payload,
  };
};

export type DashboardActionType =
  | ReturnType<typeof updateIndex>
  | ReturnType<typeof updateSelection>
  | ReturnType<typeof updateHover>;
