import usePopup from '@hooks/components/usePopup';
import useSystem from '@hooks/components/useSystem';
import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecurePost } from '@lib/api/api';
import React, { useCallback } from 'react';
import StopPopup from '../components/StopPopup';

// type Props = {
//   getUserList: () => void;
// };

const StopPopupContainer = ({}) => {
  const { hidePopup } = usePopup();
  const { getAccessToken } = useToken();
  const { __shutdownSystem } = useSystem();

  const onCancelButtonClicked = useCallback(() => {
    hidePopup();
  }, [hidePopup]);

  const onConfirmButtonClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePost(
      apiRoute.dashboard.stop,
      {},
      {},
      getAccessToken() || '',
    );

    if (status / 200 >= 100) {
      return;
    }

    __shutdownSystem();
    hidePopup();
  }, [hidePopup, __shutdownSystem, getAccessToken]);

  return (
    <StopPopup
      onCancelButtonClicked={onCancelButtonClicked}
      onConfirmButtonClicked={onConfirmButtonClicked}
    />
  );
};

export default StopPopupContainer;
