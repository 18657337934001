import { START, END } from './modules/actionType';

export const startSystem = () => {
  return {
    type: START,
  };
};

export const endSystem = () => {
  return {
    type: END,
  };
};

export type SystemActionType =
  | ReturnType<typeof startSystem>
  | ReturnType<typeof endSystem>;
