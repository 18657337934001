import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { amountOfTimeFormat, createAtFormat } from '@lib/factory';
import { BuyListItemType } from '@typedef/Buy/buy.types';
import React from 'react';
import './styles/buy.styles.css';

type Props = {
  page: number;
  maxPage: number;
  buyList: BuyListItemType[];
  onPageChanged: (page: number) => void;
};

const Buy = ({ page, maxPage, buyList, onPageChanged }: Props) => {
  return (
    <div className='buy-root'>
      <div className='table'>
        <div className='table-header'>
          <span className='col' style={{ width: '123px' }}>
            {'구매자'}
          </span>
          <span className='col' style={{ width: '123px' }}>
            {'물품명'}
          </span>
          <span className='col' style={{ width: '136px' }}>
            {'개당 시간'}
          </span>
          <span className='col' style={{ width: '50px' }}>
            {'개수'}
          </span>
          <span className='col' style={{ width: '136px' }}>
            {'지불 시간'}
          </span>
          <span className='col' style={{ width: '200px' }}>
            {'구매 시간'}
          </span>
        </div>
        <div className='table-body'>
          <ul>
            {buyList.map((item, idx) => {
              return (
                <li className='list-item'>
                  <span className='col' style={{ width: '123px' }}>
                    {item.sendUser.displayName}
                  </span>
                  <span className='col' style={{ width: '123px' }}>
                    {item.name}
                  </span>
                  <span className='col' style={{ width: '136px' }}>
                    {amountOfTimeFormat(item.tradedTime / item.count)}
                  </span>
                  <span className='col' style={{ width: '50px' }}>
                    {item.count}
                  </span>
                  <span className='col' style={{ width: '136px' }}>
                    {amountOfTimeFormat(item.tradedTime)}
                  </span>
                  <span className='col' style={{ width: '200px' }}>
                    {createAtFormat(item.createAt)}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <CPagingContainer
        page={page}
        maxPage={maxPage}
        onPageChanged={onPageChanged}
      />
    </div>
  );
};

export default Buy;
