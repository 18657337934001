import { combineReducers } from 'redux';
import counterReducer from './counter/reducer';
import popupReducer from './popup/reducer';
import systemReducer from './system/reducer';
import dashboardReducer from './dashboard/reducer';

const rootReducer = combineReducers({
  dashboardReducer,
  counterReducer,
  popupReducer,
  systemReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
