import { useCallback } from 'react';

const ACCESS_TOKEN_KEY = '@accessToken';
const REFRESH_TOKEN_KEY = '@refreshtoken';

export default function useToken() {
  const getAccessToken = useCallback(
    () => sessionStorage.getItem(ACCESS_TOKEN_KEY),
    [],
  );

  const setAccessToken = useCallback(
    (accesstoken: string) =>
      sessionStorage.setItem(ACCESS_TOKEN_KEY, accesstoken),
    [],
  );

  const clearAccessToken = useCallback(
    () => sessionStorage.removeItem(ACCESS_TOKEN_KEY),
    [],
  );

  const getRefreshToken = useCallback(
    () => sessionStorage.getItem(REFRESH_TOKEN_KEY),
    [],
  );

  const setRefreshToken = useCallback(
    (refreshtoken: string) =>
      sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshtoken),
    [],
  );

  const claerRefreshToken = useCallback(
    () => sessionStorage.removeItem(REFRESH_TOKEN_KEY),
    [],
  );

  return {
    getAccessToken,
    setAccessToken,
    clearAccessToken,
    getRefreshToken,
    setRefreshToken,
    claerRefreshToken,
  };
}
