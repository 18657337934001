import {
  updateSelection,
  updateIndex,
  updateHover,
} from "@store/dashboard/actions";
import { RootState } from "@store/rootReducer";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const DASHBOARD_KEY = "@sortedIndex";

export default function useDashboard() {
  const { selection, hoverIndex, sortedIndex } = useSelector(
    (state: RootState) => state.dashboardReducer,
  );
  const dispatch = useDispatch();

  const __updateSelection = useCallback(
    (payload: number) => {
      dispatch(updateSelection(payload));
      dispatch(updateHover(payload));
    },
    [dispatch],
  );

  const __updateIndex = useCallback(
    (payload: number[]) => {
      dispatch(updateIndex(payload));
    },
    [dispatch],
  );

  const __updateHover = useCallback(
    (payload: number) => {
      dispatch(updateHover(payload));
    },
    [dispatch],
  );

  const __storeSortedIndex = useCallback(() => {
    localStorage.setItem(DASHBOARD_KEY, JSON.stringify(sortedIndex));
  }, [sortedIndex]);

  const __initializeSortedIndex = useCallback(() => {
    let sortedArray = localStorage.getItem(DASHBOARD_KEY);
    if (sortedArray) {
      dispatch(updateIndex(JSON.parse(sortedArray)));
    }
  }, [dispatch]);

  return {
    selection,
    hoverIndex,
    sortedIndex,
    __updateIndex,
    __updateSelection,
    __updateHover,
    __storeSortedIndex,
    __initializeSortedIndex,
  };
}
