import Sortable from '@components/Board/hoc/Sortable';
import { BankItemStateType } from '@typedef/Bank/bank.types';
import { SendTimeType } from '@typedef/Board/board.types';
import { UserType } from '@typedef/common/user.types';
import React from 'react';
import UserItemContainer from './containers/UserItemContainer';
import './styles/bank.style.css';

type Props = {
  onEntireClicked: () => void;
  onTimeChanged: (time: keyof SendTimeType, input: string) => void;
  onTimeClicked: (time: number) => void;
  onResetClicked: () => void;
  onSendClicked: () => void;
  onBankCheckClicked: (index: number) => void;
  onSignToggleClicked: () => void;
  onAdjustClicked: () => void;
  userList: UserType[];
  sendTime: SendTimeType;
  stateList: BankItemStateType[];
  isPlus: boolean;
  interest: string;
  sortedIndex: number[];
  onInterestChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInterestInputFocus: () => void;
  onInterestInputBlur: () => void;
};

const Bank = ({
  onEntireClicked,
  onTimeChanged,
  onTimeClicked,
  onResetClicked,
  onSendClicked,
  onBankCheckClicked,
  onSignToggleClicked,
  onAdjustClicked,
  userList,
  sendTime,
  stateList,
  isPlus,
  interest,
  sortedIndex,
  onInterestChange,
  onInterestInputFocus,
  onInterestInputBlur,
}: Props) => {
  return (
    <div className='bank-root'>
      <div className='interest-input-section'>
        <span className='text'>이자</span>
        <input
          type='text'
          className='input'
          value={interest}
          onChange={onInterestChange}
          onFocus={onInterestInputFocus}
          onBlur={onInterestInputBlur}
        />
        <button className='adjust-button' onClick={onAdjustClicked}>
          적용
        </button>
      </div>
      <div className='time-sender'>
        <div className='select-section'>
          <div className='time-select'>
            <button className='time-button' onClick={onEntireClicked}>
              전체선택
            </button>
            <button className='time-button' onClick={() => onTimeClicked(10)}>
              +10분
            </button>
            <button className='time-button' onClick={() => onTimeClicked(30)}>
              +30분
            </button>
            <button className='time-button' onClick={() => onTimeClicked(60)}>
              +1시간
            </button>
            <button className='time-button' onClick={() => onTimeClicked(1440)}>
              +24시간
            </button>
          </div>
          <div className='time-select'>
            <button className='time-button' onClick={onResetClicked}>
              선택초기화
            </button>
            <button className='time-button' onClick={() => onTimeClicked(-10)}>
              -10분
            </button>
            <button className='time-button' onClick={() => onTimeClicked(-30)}>
              -30분
            </button>
            <button className='time-button' onClick={() => onTimeClicked(-60)}>
              -1시간
            </button>
            <button
              className='time-button'
              onClick={() => onTimeClicked(-1440)}>
              -24시간
            </button>
          </div>
        </div>
        <div className='input-section'>
          <div className='row'>
            <button className={'sign-toggle'} onClick={onSignToggleClicked}>
              <div className='sign'>
                <span>+</span>
              </div>
              <div className='sign'>
                <span>-</span>
              </div>
              <div className={`switch ${isPlus ? '' : 'minus'}`}></div>
            </button>
            <input
              value={Math.abs(sendTime.hour)}
              className='input'
              onChange={(e) => onTimeChanged('hour', e.target.value)}
            />
            <span className='text'>시간</span>
            <input
              value={Math.abs(sendTime.minute)}
              className='input'
              onChange={(e) => onTimeChanged('minute', e.target.value)}
            />
            <span className='text'>분</span>
            <input
              value={Math.abs(sendTime.second)}
              className='input'
              onChange={(e) => onTimeChanged('second', e.target.value)}
            />
            <span className='text'>초</span>
          </div>
          <div className='row'>
            <button className='send-button' onClick={onSendClicked}>
              적용
            </button>
          </div>
        </div>
      </div>
      <div className='user-board-container'>
        {userList.length !== 0 &&
          sortedIndex.map((index, i) => (
            <Sortable location={i} index={index} key={userList[index].id}>
              <UserItemContainer
                key={userList[index].id}
                user={userList[index]}
                state={stateList[index]}
                index={index}
                onBankCheckClicked={onBankCheckClicked}
              />
            </Sortable>
          ))}
      </div>
    </div>
  );
};

export default Bank;
