import HomeContainer from '@components/Home/containers/HomeContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import LoginNavigationContainer from './containers/LoginNavigationContainer';
import MainNavigationContainer from './containers/MainNavigationContainer';

type Props = {
  root: 'login' | 'main';
  Popup: JSX.Element | null;
  translateY: number;
  onBackdropClicked: () => void;
};

const RootNavigation = ({
  root,
  Popup,
  translateY,
  onBackdropClicked,
}: Props) => {
  return (
    <BrowserRouter>
      {root === 'login' && <LoginNavigationContainer />}
      {root === 'main' && <MainNavigationContainer />}
      {Popup && (
        <div className='popup-container'>
          <div className='backdrop' onClick={onBackdropClicked}></div>
          <div
            className='popup'
            style={{ transform: `translateY(${translateY}vh)` }}>
            {Popup}
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default RootNavigation;
