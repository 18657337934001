import React from 'react';
import './styles/livetime.style.css';

type Props = {
  date: string;
  time: string;
  second: number;
  onDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSecondChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onResetButtonClicked: () => void;
  onSettingButtonClicked: () => void;
};

const LiveTime = ({
  date,
  time,
  second,
  onDateChange,
  onTimeChange,
  onSecondChange,
  onResetButtonClicked,
  onSettingButtonClicked,
}: Props) => {
  return (
    <div className='livetime-root'>
      <div className='time-picker'>
        <input type='date' value={date} onChange={onDateChange} />
        <input type='time' value={time} onChange={onTimeChange} />
        <input
          type='number'
          placeholder='00'
          min={0}
          max={59}
          value={second}
          onChange={onSecondChange}
        />
      </div>
      <div className='button-wrapper'>
        <button className='reset-button' onClick={onResetButtonClicked}>
          초기화하기
        </button>
        <button className='setting-button' onClick={onSettingButtonClicked}>
          설정하기
        </button>
      </div>
    </div>
  );
};

export default LiveTime;
