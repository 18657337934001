import useDashboard from "@hooks/components/useDashboard";

import React, { useCallback, useEffect, useRef, useState } from "react";

type Props = {
  children: JSX.Element;
  index: number;
  location: number;
};

const Sortable = ({ children, index, location }: Props) => {
  const {
    selection,
    sortedIndex,
    hoverIndex,
    __updateSelection,
    __updateHover,
    __updateIndex,
    __storeSortedIndex,
  } = useDashboard();

  const [mousePressed, setMousePressed] = useState(false);
  const [dragEnter, setDragEnter] = useState(false);
  const sortableRef = useRef<HTMLDivElement>(null);

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setMousePressed(true);
      __updateSelection(location);
    },
    [location, __updateSelection],
  );

  const onDragEnd = useCallback(() => {
    setMousePressed(false);
  }, []);

  const onMouseUp = useCallback(() => {
    setMousePressed(false);
  }, []);

  const onDragEnter = useCallback(() => {
    let indexArray: number[] = Object.assign([], sortedIndex);

    if (!mousePressed && selection !== index) {
      setDragEnter(true);
    }
    if (!dragEnter && !mousePressed && hoverIndex !== null) {
      let temp = indexArray[hoverIndex];
      indexArray[hoverIndex] = indexArray[location];
      indexArray[location] = temp;
      __updateHover(location);
      __updateIndex(indexArray);
    }

    //다른 인덱스에 selection이 있는 상태에서 발동시 빈 인덱스로 한칸 이동
    //정렬 실행
  }, [
    index,
    mousePressed,
    selection,
    dragEnter,
    location,
    sortedIndex,
    hoverIndex,
  ]);

  const onDrop = useCallback(() => {
    __storeSortedIndex();
  }, [__storeSortedIndex]);

  useEffect(() => {
    if (hoverIndex !== index) {
      setDragEnter(false);
    }
  }, [hoverIndex]);

  return (
    <div
      className={`sortable-root`}
      draggable
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      style={{
        opacity: mousePressed ? 0.2 : 1,
        zIndex: mousePressed ? 20 : 1,
      }}
      ref={sortableRef}>
      {children}
    </div>
  );
};

export default Sortable;
