import AlarmSidebarContainer from '@components/AlarmSidebar/containers/AlarmSidebarContainer';
import BoardContainer from '@components/Board/containers/BoardContainer';
import BankContainer from '@components/Bank/containers/BankContainer';
import BuyContainer from '@components/Buy/containers/BuyContainer';
import GNBContainer from '@components/GNB/containers/GNBContainer';
import SendContainer from '@components/Send/containers/SendContainer';
import TotalContainer from '@components/Total/containers/TotalContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import '../styles/main.navigation.styles.css';
import HeaderContainer from '@components/Header/containers/HeaderContainer';
import LiveTimeContainer from '@components/Livetime/containers/LiveTimeContainer';
import NotFoundContainer from '@components/NotFound/containers/NotFoundContainer';

const MainNavigation = () => {
  return (
    <div className='main-navigation-root'>
      <HeaderContainer />
      <div className='main'>
        <GNBContainer />
        <div className='main-content'>
          <Routes>
            <Route path='/' element={<BoardContainer />} />
            <Route path='/send' element={<SendContainer />} />
            <Route path='/buy' element={<BuyContainer />} />
            <Route path='/total' element={<TotalContainer />} />
            <Route path='/bank' element={<BankContainer />} />
            <Route path='/livetime' element={<LiveTimeContainer />} />
            <Route path='*' element={<NotFoundContainer />} />
          </Routes>
        </div>
        <AlarmSidebarContainer />
      </div>
    </div>
  );
};

export default MainNavigation;
