import { SendListItemTypes } from '@typedef/Send/send.types';
import React from 'react';
import AlarmItemContainer from './containers/AlarmItemContainer';
import './styles/alarm.sidebar.styles.css';

type Props = {
  sendList: SendListItemTypes[];
};

const AlarmSidebar = ({ sendList }: Props) => {
  return (
    <div className='alarm-sidebar-root'>
      <ul>
        {sendList.map((item) => {
          return <AlarmItemContainer item={item} key={item.id} />;
        })}
      </ul>
    </div>
  );
};

export default AlarmSidebar;
