import useToken from '@hooks/store/useToken';
import { apiRoute, BasicListDataType, requestSecureGet } from '@lib/api/api';
import { BuyListItemType } from '@typedef/Buy/buy.types';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Buy from '../Buy';

const BuyContainer = () => {
  const { getAccessToken } = useToken();
  const [buyList, setBuyList] = useState<BuyListItemType[]>([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(16);

  const onPageChanged = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onListLoad = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<BuyListItemType>>(
      apiRoute.buy.getList + `?page=${page}&perPage=${12}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alert('구매 내역 조회에 실패했습니다.');
      return;
    }

    setBuyList(data.rows);
    setMaxPage(data.maxPage);
  }, [page]);

  useEffect(() => {
    onListLoad();
  }, [onListLoad]);

  return (
    <Buy
      page={page}
      maxPage={maxPage}
      buyList={buyList}
      onPageChanged={onPageChanged}
    />
  );
};

export default BuyContainer;
