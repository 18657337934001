import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecureGet } from '@lib/api/api';
import { SendListItemTypes } from '@typedef/Send/send.types';
import React from 'react';
import { useCallback } from 'react';
import AlarmItem from '../components/AlarmItem';

type Props = {
  item: SendListItemTypes;
};

const AlarmItemContainer = ({ item }: Props) => {
  const { getAccessToken } = useToken();

  const onAllowClicked = useCallback(
    async (isAllowed: boolean) => {
      const {
        data,
        config: { status },
      } = await requestSecureGet<{}>(
        `${apiRoute.send.allow}?id=${item.id}&allow=${isAllowed}`,
        {},
        getAccessToken()!,
      );

      if (status !== 200) {
        alert(`전송 요청 ${isAllowed ? '허가' : '거절'}에 실패했습니다.`);
        return;
      }
    },
    [item],
  );

  return <AlarmItem item={item} onAllowClicked={onAllowClicked} />;
};

export default AlarmItemContainer;
