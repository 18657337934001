import usePopup from "@hooks/components/usePopup";
import { convertTwoDigits } from "@lib/factory";
import { BoardItemStateType, Coordinate } from "@typedef/Board/board.types";
import { UserType } from "@typedef/common/user.types";
import React, { useCallback, useMemo, useState } from "react";
import UserItem from "../components/UserItem";
import NameChangePopupContainer from "./NameChangePopupContainer";

type Props = {
  user: UserType;
  state: BoardItemStateType;
  index: number;
  onBoardCheckClicked: (index: number) => void;
  onBoardLockCliked: (index: number) => void;
};

const UserItemContainer = ({
  user,
  state,
  index,
  onBoardCheckClicked,
  onBoardLockCliked,
}: Props) => {
  //파란시간: timeRed(유저가 죽는 시점) - timeLive()
  //빨간시간: timeRed - 현재
  const { displayName, timeLive, timeRed } = user;
  const { showPopup } = usePopup();

  const [name, setName] = useState(displayName);

  const formattedRedTime = useMemo(() => {
    let time = timeRed - parseInt(Date.now() / 1000 + "");
    let isplus = true;
    if (time < 0) {
      isplus = false;
      time = -time;
    }
    let hour = Math.floor(time / 3600);
    let temp = time % 3600;
    let minute = Math.floor(temp / 60);
    let second = Math.floor(temp % 60);

    return `${isplus ? "" : "-"}${convertTwoDigits(hour)}:${convertTwoDigits(
      minute,
    )}:${convertTwoDigits(second)}`;
  }, [timeRed, user]);

  const formattedBlueTime = useMemo(() => {
    let time = timeRed - timeLive;
    let isplus = true;
    if (time < 0) {
      isplus = false;
      time = -time;
    }
    let hour = Math.floor(time / 3600);
    let temp = time % 3600;
    let minute = Math.floor(temp / 60);
    let second = Math.floor(temp % 60);

    return `${isplus ? "" : "-"}${convertTwoDigits(hour)}:${convertTwoDigits(
      minute,
    )}:${convertTwoDigits(second)}`;
  }, [timeRed, user]);

  const onUserChanged = useCallback((changedName: string) => {
    setName(changedName);
  }, []);

  const onCheckClicked = useCallback(() => {
    onBoardCheckClicked(index);
  }, [index, onBoardCheckClicked]);

  const onLockClicked = useCallback(() => {
    onBoardLockCliked(index);
  }, [index, onBoardLockCliked]);

  const onNameClicked = useCallback(() => {
    showPopup(
      <NameChangePopupContainer
        userId={user.id}
        onUserChanged={onUserChanged}
      />,
    );
  }, [showPopup, NameChangePopupContainer]);

  return (
    <UserItem
      state={state}
      user={name}
      redTime={formattedRedTime}
      blueTime={formattedBlueTime}
      onCheckClicked={onCheckClicked}
      onLockClicked={onLockClicked}
      onNameClicked={onNameClicked}
    />
  );
};

export default UserItemContainer;
