import { SystemActionType } from './actions';

const initialState: SystemState = 'shutdown';

export type SystemState = 'processing' | 'shutdown';

export default function reducer(
  state = initialState,
  { type }: SystemActionType,
): SystemState {
  switch (type) {
    case 'START':
      return 'processing';
    case 'END':
      return 'shutdown';
    default:
      return state;
  }
}
