import usePopup from '@hooks/components/usePopup';
import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import RootNavigation from '@routes/RootNavigation';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';

const RootNavigationContainer = () => {
  const [root, setRoot] = useState<'login' | 'main'>('main');

  const { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } =
    useToken();
  const { getRootRoute, setRootRoute } = useRootRoute();
  const { Popup, hidePopup } = usePopup();

  const [checked, setChecked] = useState(false);
  const [translateY, setTranslateY] = useState(80);

  const onBackdropClicked = useCallback(() => {
    hidePopup();
  }, [hidePopup]);

  const checkRoot = useCallback(async () => {
    const root = getRootRoute();

    if (!root) {
      setChecked(true);
      return;
    }

    if (root === 'main') {
      const accessToken = getAccessToken();

      if (!accessToken) {
        setRootRoute('login');
        setChecked(true);

        return;
      }

      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        setRootRoute('login');

        setChecked(true);
        return;
      }

      setChecked(true);
    } else {
      setChecked(true);
    }
  }, [getRootRoute, getAccessToken]);

  useEffect(() => {
    checkRoot();
  }, []);

  useEffect(() => {
    if (Popup) {
      setTimeout(() => setTranslateY(0), 10);
    } else {
      setTranslateY(80);
    }
  }, [Popup]);

  return checked ? (
    <RootNavigation
      root={getRootRoute() || 'login'}
      Popup={Popup}
      translateY={translateY}
      onBackdropClicked={onBackdropClicked}
    />
  ) : (
    <></>
  );
};

export default RootNavigationContainer;
