import { alarmCreateAtFormat, amountOfTimeFormat } from '@lib/factory';
import { SendListItemTypes } from '@typedef/Send/send.types';
import React from 'react';

type Props = {
  item: SendListItemTypes;
  onAllowClicked: (isAllowed: boolean) => void;
};

const AlarmItem = ({ item, onAllowClicked }: Props) => {
  return (
    <li className='alarm-item'>
      <div className='title'>{'알림'}</div>
      <div className='message'>
        <p className='main'>{`${item.sendUser.displayName}(이)가 ${
          item.recievedUser.displayName
        }에게 ${
          item.tradedTime ? amountOfTimeFormat(item.tradedTime) : '0초'
        }를 전송했습니다`}</p>
        <span className='sub'>{`${alarmCreateAtFormat(item.createAt)}`}</span>
      </div>
      <div className='button-container'>
        <button className='btn reject' onClick={() => onAllowClicked(false)}>
          {'거절'}
        </button>
        <button className='btn allow' onClick={() => onAllowClicked(true)}>
          {'승인'}
        </button>
      </div>
    </li>
  );
};

export default AlarmItem;
