import usePopup from '@hooks/components/usePopup';
import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecurePatch } from '@lib/api/api';
import React, { useCallback, useState } from 'react';
import NameChangePopup from '../components/NameChangePopup';

type Props = {
  userId: string;
  onUserChanged: (name: string) => void;
};

const NameChangePopupContainer = ({ userId, onUserChanged }: Props) => {
  const { hidePopup } = usePopup();
  const { getAccessToken } = useToken();
  const [name, setName] = useState('');

  const onNameChange = useCallback(
    (input: React.ChangeEvent<HTMLInputElement>) => {
      setName(input.target.value);
    },
    [],
  );

  const onCancelButtonClicked = useCallback(() => {
    setName('');
    hidePopup();
  }, [hidePopup]);

  const onConfirmButtonClicked = useCallback(async () => {
    if (name === '') {
      alert('이름을 입력해주세요');
      return;
    }
    onUserChanged(name);
    const {} = await requestSecurePatch(
      apiRoute.dashboard.name + `?id=${userId}&name=${name}`,
      {},
      {},
      getAccessToken() || '',
    );
    hidePopup();
  }, [hidePopup, name, onUserChanged]);

  return (
    <NameChangePopup
      name={name}
      onNameChange={onNameChange}
      onCancelButtonClicked={onCancelButtonClicked}
      onConfirmButtonClicked={onConfirmButtonClicked}
    />
  );
};

export default NameChangePopupContainer;
