import useToken from '@hooks/store/useToken';
import { apiRoute, BasicListDataType, requestSecureGet } from '@lib/api/api';
import { UserType } from '@typedef/common/user.types';
import { SendListItemTypes } from '@typedef/Send/send.types';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import Send from '../Send';

const SendContainer = () => {
  const { getAccessToken, getRefreshToken } = useToken();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(16);
  const [sendList, setSendList] = useState<SendListItemTypes[]>([]);

  const onPageChanged = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onListLoad = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<SendListItemTypes>>(
      apiRoute.send.getList + `?page=${page}&perPage=${12}&isChecked=true`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alert('전송 내역 조회에 실패했습니다.');
      return;
    }

    setSendList(data.rows);
    setMaxPage(data.maxPage);
  }, [page]);

  useEffect(() => {
    onListLoad();
  }, [onListLoad]);

  return (
    <Send
      sendList={sendList}
      page={page}
      maxPage={maxPage}
      onPageChanged={onPageChanged}
    />
  );
};

export default SendContainer;
