import { images } from '@assets/images';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { amountOfTimeFormat } from '@lib/factory';
import { UserType } from '@typedef/common/user.types';
import { TotalListItemType } from '@typedef/Total/total.types';

import React from 'react';
import './styles/total.styles.css';

type Props = {
  page: number;
  maxPage: number;
  date: string;
  totalList: TotalListItemType[];
  datepickerRef: React.RefObject<HTMLInputElement>;
  onPageChanged: (page: number) => void;
  onDateChanged: (date: string) => void;
};

const Total = ({
  page,
  maxPage,
  totalList,
  date,
  datepickerRef,
  onDateChanged,

  onPageChanged,
}: Props) => {
  return (
    <div className='total-root'>
      <div className='date-picker-container'>
        <label htmlFor='total-datepicker' className='datepicker-label' />
        <input
          ref={datepickerRef}
          type={'date'}
          name='datepicker'
          id='total-datepicker'
          value={date}
          onChange={(e) => onDateChanged(e.target.value)}
        />
      </div>
      <div className='table'>
        <div className='table-header'>
          <span className='col' style={{ width: '80px' }}>
            {' '}
          </span>
          <span className='col' style={{ width: '123px' }}>
            {'사용자명'}
          </span>
          <span className='col' style={{ width: '136px' }}>
            {'총 사용 시간'}
          </span>
        </div>
        <div className='table-body'>
          <ul>
            {totalList.map((item, idx) => {
              return (
                <li className='list-item'>
                  <span className='col' style={{ width: '80px' }}>
                    {String(page * 12 + idx + 1).padStart(2, '0')}
                  </span>
                  <span className='col' style={{ width: '123px' }}>
                    {item.name}
                  </span>
                  <span className='col' style={{ width: '136px' }}>
                    {item.time ? amountOfTimeFormat(item.time) : '0초'}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <CPagingContainer
        page={page}
        maxPage={maxPage}
        onPageChanged={onPageChanged}
      />
    </div>
  );
};

export default Total;
