import useDashboard from '@hooks/components/useDashboard';
import useToken from '@hooks/store/useToken';
import {
  apiRoute,
  BasicListDataType,
  requestSecureGet,
  requestSecurePatch,
} from '@lib/api/api';
import { BankItemStateType } from '@typedef/Bank/bank.types';
import { SendTimeType } from '@typedef/Board/board.types';
import { UserType } from '@typedef/common/user.types';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Bank from '../Bank';

const BankContainer = () => {
  //TODO: 이자값 state input mode, displaymode
  const { getAccessToken } = useToken();
  const { sortedIndex, __initializeSortedIndex } = useDashboard();

  const [interest, setInterest] = useState('0');
  const [interestInputMode, setInterestInputMode] = useState<boolean>(false);
  const [second, setSecond] = useState(0);
  const [sendTime, setSendTime] = useState<SendTimeType>({
    hour: 0,
    minute: 0,
    second: 0,
  });
  const [userList, setUserList] = useState<UserType[]>([]);
  const [stateList, setStateList] = useState<BankItemStateType[]>(
    new Array(12).fill('unchecked'),
  );
  const isPlus = useMemo(() => second >= 0, [second]);
  const interestDisplay = useMemo(() => {
    return interest + (interestInputMode ? '' : '%');
  }, [interest, interestInputMode]);

  const onEntireClicked = useCallback(() => {
    setStateList(new Array(12).fill('checked'));
  }, []);

  const onResetClicked = useCallback(() => {
    setStateList(new Array(12).fill('unchecked'));
  }, []);

  const onTimeChanged = useCallback(
    (time: keyof SendTimeType, input: string) => {
      if (/[^0-9]+/.test(input)) {
        return;
      }
      let inputTime = parseInt(input) || 0;
      let second = 0;
      if (time === 'hour') {
        second =
          (inputTime * 3600 + sendTime.minute * 60 + sendTime.second) *
          (isPlus ? 1 : -1);
      } else if (time === 'minute') {
        second =
          (sendTime.hour * 3600 + inputTime * 60 + sendTime.second) *
          (isPlus ? 1 : -1);
      } else {
        second =
          (sendTime.hour * 3600 + sendTime.minute * 60 + inputTime) *
          (isPlus ? 1 : -1);
      }

      setSecond(second);
    },
    [isPlus, sendTime],
  );

  const onTimeClicked = useCallback((time: number) => {
    setSecond((prev) => prev + time * 60);
  }, []);

  const onSignToggleClicked = useCallback(() => {
    setSecond((prev) => -prev);
  }, []);

  const onSendClicked = useCallback(() => {
    Promise.all(
      stateList.map((state, index) => {
        if (state === 'checked') {
          const user = userList[index];
          if (user.timeRed - parseInt(Date.now() / 1000 + '') < second) {
            window.alert(
              `예금하는 시간이 ${user.displayName}이 가진 시간보다 많습니다`,
            );
            return;
          }
          if (!isPlus && user.savedTime < Math.abs(second)) {
            console.log(second);
            window.alert(
              `인출하는 시간이 ${user.displayName}이 예금한 시간보다 많습니다`,
            );
            return;
          }
          return requestSecurePatch<{}>(
            apiRoute.bank.time + `?id=${user.id}&time=${second}`,
            {},
            {},
            getAccessToken() || '',
          );
        }
      }),
    ).then((_) => {
      setSecond(0);
    });
  }, [userList, second, stateList, isPlus]);

  const onBankCheckClicked = useCallback(
    (index: number) => {
      let chaged: BankItemStateType[] = Object.assign([], stateList);
      if (chaged[index] === 'checked') {
        chaged[index] = 'unchecked';
      } else if (chaged[index] === 'unchecked') {
        chaged[index] = 'checked';
      }
      setStateList(chaged);
    },
    [stateList],
  );

  const getUserList = useCallback(async () => {
    const {
      data: { rows },
      config: { status },
    } = await requestSecureGet<BasicListDataType<UserType>>(
      apiRoute.dashboard.getUserList + '?page=0&perPage=12',
      {},
      getAccessToken() ?? '',
    );

    if (status !== 200) {
      return;
    }

    setUserList(rows);
  }, []);

  const getInterest = useCallback(async () => {
    await axios
      .get(apiRoute.bank.interest, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken() || ''}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          return;
        }
        setInterest(response.data);
      })
      .catch((err) => {
        return;
      });
  }, []);

  const onAdjustClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.bank.interest + `?rate=${interest}`,
      {},
      {},
      getAccessToken() || '',
    );
  }, [interest]);

  const onInterestChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInterest(e.target.value);
    },
    [],
  );

  const onInterestInputFocus = useCallback(() => {
    setInterestInputMode(true);
  }, []);

  const onInterestInputBlur = useCallback(() => {
    setInterestInputMode(false);
  }, []);

  useEffect(() => {
    getUserList();
    getInterest();
  }, []);

  useEffect(() => {
    let timeout = setTimeout(getUserList, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [userList, getUserList]);

  useEffect(() => {
    let absoluteSecond = Math.abs(second);
    setSendTime({
      hour: Math.floor(absoluteSecond / 3600),
      minute: Math.floor((absoluteSecond % 3600) / 60),
      second: absoluteSecond % 60,
    });
  }, [second]);

  useEffect(() => {
    __initializeSortedIndex();
  }, []);

  return (
    <Bank
      onEntireClicked={onEntireClicked}
      onTimeChanged={onTimeChanged}
      onTimeClicked={onTimeClicked}
      onResetClicked={onResetClicked}
      onSendClicked={onSendClicked}
      onBankCheckClicked={onBankCheckClicked}
      onSignToggleClicked={onSignToggleClicked}
      onAdjustClicked={onAdjustClicked}
      sendTime={sendTime}
      userList={userList}
      stateList={stateList}
      isPlus={isPlus}
      interest={interestDisplay}
      onInterestChange={onInterestChange}
      onInterestInputFocus={onInterestInputFocus}
      onInterestInputBlur={onInterestInputBlur}
      sortedIndex={sortedIndex}
    />
  );
};

export default BankContainer;
