export function amountOfTimeFormat(time: number) {
  const isPositive = time > 0 ? true : false;
  const absoluteTime = Math.abs(time);
  const hours = Math.floor(absoluteTime / 3600);
  const minutes = Math.floor((absoluteTime - hours * 3600) / 60);
  const seconds = absoluteTime % 60;

  return `${isPositive ? '' : '-'} ${hours ? `${hours}시간` : ''} ${
    minutes ? `${minutes}분` : ''
  } ${seconds ? `${seconds}초` : ''}`;
}

export function createAtFormat(createAt: string) {
  const date = new Date(createAt);

  return `${createAt.slice(0, 4)}년 ${createAt.slice(5, 7)}월 ${createAt.slice(
    8,
    10,
  )}일 ${createAt.slice(11, 13)}시 ${createAt.slice(14, 16)}분`;
}

export function alarmCreateAtFormat(createAt: string) {
  const createTime = new Date(createAt.slice(0, 19)).getTime();
  const now = new Date().getTime();

  const amount = (now - createTime) / 1000;

  const hours = Math.floor(amount / 3600);
  const minutes = Math.floor((amount - hours * 3600) / 60);

  return `${hours ? `${hours}시간` : ''} ${minutes}분 전`;
}

export function convertTwoDigits(num: number) {
  return num.toString().padStart(2, '0');
}

export function todayFormat() {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`;
}
