import MainNavigation from '@routes/components/MainNavigation';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MainNavigationContainer = () => {
  return <MainNavigation />;
};

export default MainNavigationContainer;
