import { DashboardActionType } from './actions';

const initialState: DashboardType = {
  selection: null,
  sortedIndex: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  hoverIndex: 0,
};

export type DashboardType = {
  selection: number | null;
  sortedIndex: number[];
  hoverIndex: number | null;
};

export default function reducer(
  state = initialState,
  { type, payload }: DashboardActionType,
): DashboardType {
  switch (type) {
    case 'UPDATE_INDEX':
      return {
        ...state,
        sortedIndex: payload,
      };
    case 'UPDATE_SELECTION':
      return {
        ...state,
        selection: payload,
      };
    case 'UPDATE_HOVER':
      return {
        ...state,
        hoverIndex: payload,
      };
    default:
      return state;
  }
}
