import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import { apiRoute, requestPost, requestSecurePost } from '@lib/api/api';
import { LoginFormType } from '@typedef/Login/login.types';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import Login from '../Login';

const LoginContainer = () => {
  const { setAccessToken, setRefreshToken } = useToken();
  const { setRootRoute } = useRootRoute();

  const [loginForm, setLoginForm] = useState<LoginFormType>({ id: '', pw: '' });
  const [isVisible, setIsVisible] = useState(false);

  const onLoginFormChanged = useCallback(
    (key: keyof LoginFormType, value: string) => {
      setLoginForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    [],
  );

  const onDeleteInputClicked = useCallback(() => {
    setLoginForm({ ...loginForm, id: '' });
  }, [loginForm]);

  const onPasswordToggleClicked = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const onLoginBtnClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestPost<{ accessToken: string; refreshToken: string }>(
      apiRoute.auth.adminLogin,
      {},
      { userName: loginForm.id, password: loginForm.pw },
    );

    if (status !== 200) {
      alert('로그인에 실패했습니다.');
      return;
    }

    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    setRootRoute('main');

    window.location.reload();
  }, [loginForm]);

  return (
    <Login
      loginForm={loginForm}
      isVisible={isVisible}
      onLoginFormChanged={onLoginFormChanged}
      onDeleteInputClicked={onDeleteInputClicked}
      onPasswordToggleClicked={onPasswordToggleClicked}
      onLoginBtnClicked={onLoginBtnClicked}
    />
  );
};

export default LoginContainer;
