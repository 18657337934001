import usePopup from '@hooks/components/usePopup';
import useSystem from '@hooks/components/useSystem';
import useToken from '@hooks/store/useToken';
import { apiRoute, requestSecurePost } from '@lib/api/api';
import React, { useCallback } from 'react';
import EndPopup from '../components/EndPopup';
import StartPopup from '../components/StartPopup';

// type Props = {
//   getUserList: () => void;
// };

const StartPopupContainer = ({}) => {
  const { hidePopup } = usePopup();
  const { getAccessToken } = useToken();
  const { __startSystem } = useSystem();

  const onCancelButtonClicked = useCallback(() => {
    hidePopup();
  }, [hidePopup]);

  const onConfirmButtonClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePost(
      apiRoute.dashboard.start,
      {},
      {},
      getAccessToken() || '',
    );

    if (status !== 201) {
      alert(data);
      return;
    }

    __startSystem();
    hidePopup();
  }, [hidePopup, __startSystem, getAccessToken]);

  return (
    <StartPopup
      onCancelButtonClicked={onCancelButtonClicked}
      onConfirmButtonClicked={onConfirmButtonClicked}
    />
  );
};

export default StartPopupContainer;
