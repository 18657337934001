import { GNBTabTypes } from '@typedef/GNB/gnb.types';
import React from 'react';
import './styles/gnb.styles.css';

type Props = {
  gnbTab: GNBTabTypes[];
  selectedTab: string;
  onTabClicked: (path: string) => void;
};

const GNB = ({ gnbTab, selectedTab, onTabClicked }: Props) => {
  return (
    <div className='gnb-root'>
      <ul>
        {gnbTab.map((item, idx) => {
          return (
            <li
              key={idx}
              className={`tab ${selectedTab === item.path ? 'active' : ''}`}
              onClick={() => onTabClicked(item.path)}>
              <div>
                <img
                  className='icon'
                  src={
                    selectedTab === item.path
                      ? item.icon.active
                      : item.icon.inactive
                  }
                  alt='아이콘'
                />
              </div>
              <span>{item.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GNB;
