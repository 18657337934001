import { convertTwoDigits } from '@lib/factory';
import { BankItemStateType } from '@typedef/Bank/bank.types';
import { UserType } from '@typedef/common/user.types';
import React, { useCallback, useMemo } from 'react';
import UserItem from '../components/UserItem';

type Props = {
  user: UserType;
  state: BankItemStateType;
  index: number;
  onBankCheckClicked: (index: number) => void;
};

const UserItemContainer = ({
  user,
  state,
  index,
  onBankCheckClicked,
}: Props) => {
  const { displayName, savedTime } = user;
  const formattedTime = useMemo(() => {
    if (savedTime <= 0) {
      return '00:00:00';
    }

    let hour = Math.floor(savedTime / 3600);
    let temp = savedTime % 3600;
    let minute = Math.floor(temp / 60);
    let second = Math.floor(temp % 60);

    return `${convertTwoDigits(hour)}:${convertTwoDigits(
      minute,
    )}:${convertTwoDigits(second)}`;
  }, [user]);

  const onCheckClicked = useCallback(() => {
    onBankCheckClicked(index);
  }, [index, onBankCheckClicked]);

  return (
    <UserItem
      state={state}
      user={displayName}
      time={formattedTime}
      onCheckClicked={onCheckClicked}
    />
  );
};

export default UserItemContainer;
