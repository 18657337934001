import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import { apiRoute, BasicListDataType, requestSecureGet } from '@lib/api/api';
import { SendListItemTypes } from '@typedef/Send/send.types';
import React, { useCallback, useEffect, useState } from 'react';
import AlarmSidebar from '../AlarmSidebar';

const AlarmSidebarContainer = () => {
  const { getAccessToken, getRefreshToken } = useToken();

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(16);
  const [sendList, setSendList] = useState<SendListItemTypes[]>([]);

  const onPageChanged = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onListLoad = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<SendListItemTypes>>(
      apiRoute.send.getList + `?page=${page}&perPage=${50}&isChecked=false`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alert('전송 내역 조회에 실패했습니다.');
      return;
    }

    setSendList(data.rows);
    setMaxPage(data.maxPage);
  }, [page]);

  useEffect(() => {
    const timer = setInterval(() => {
      onListLoad();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <AlarmSidebar sendList={sendList} />;
};

export default AlarmSidebarContainer;
