import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '@store/rootReducer';
import { resetPopup, updatePopup } from '@store/popup/actions';

export default function usePopup() {
  const dispatch = useDispatch();
  const { popup: Popup } = useSelector(
    (state: RootState) => state.popupReducer,
  );

  const showPopup = useCallback(
    (popup: JSX.Element) => {
      dispatch(updatePopup(popup));
    },
    [dispatch, updatePopup],
  );

  const hidePopup = useCallback(() => {
    dispatch(resetPopup());
  }, [dispatch, resetPopup]);

  return {
    Popup,
    showPopup,
    hidePopup,
  };
}
