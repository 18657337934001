import useDashboard from '@hooks/components/useDashboard';
import usePopup from '@hooks/components/usePopup';
import useSystem from '@hooks/components/useSystem';
import useToken from '@hooks/store/useToken';
import {
  apiRoute,
  BasicListDataType,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@lib/api/api';
import { BoardItemStateType, SendTimeType } from '@typedef/Board/board.types';
import { UserType } from '@typedef/common/user.types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Board from '../Board';
import EndPopupContainer from './EndPopupContainer';
import StartPopupContainer from './StartPopupContainer';
import StopPopupContainer from './StopPopupContainer';

const BoardContainer = () => {
  const { getAccessToken } = useToken();
  const { showPopup } = usePopup();
  const { systemState, __startSystem, __shutdownSystem } = useSystem();
  const { sortedIndex, __initializeSortedIndex } = useDashboard();

  const boardRef = useRef<HTMLDivElement>(null);
  const [second, setSecond] = useState(0);
  const [sendTime, setSendTime] = useState<SendTimeType>({
    hour: 0,
    minute: 0,
    second: 0,
  });
  const [stateList, setStateList] = useState<BoardItemStateType[]>(
    new Array(12).fill('unchecked'),
  );
  const [userList, setUserList] = useState<UserType[]>([]);
  const isPlus = useMemo(() => second >= 0, [second]);

  const onEntireClicked = useCallback(() => {
    setStateList((prev) =>
      prev.map((state) => {
        if (state === 'disabled') {
          return 'disabled';
        } else {
          return 'checked';
        }
      }),
    );
  }, []);

  const onResetClicked = useCallback(() => {
    setStateList((prev) =>
      prev.map((state) => {
        if (state === 'disabled') {
          return 'disabled';
        } else {
          return 'unchecked';
        }
      }),
    );
  }, []);

  const onTimeChanged = useCallback(
    (time: keyof SendTimeType, input: string) => {
      if (/[^0-9]+/.test(input)) {
        return;
      }
      let inputTime = parseInt(input) || 0;
      let second = 0;
      if (time === 'hour') {
        second =
          (inputTime * 3600 + sendTime.minute * 60 + sendTime.second) *
          (isPlus ? 1 : -1);
      } else if (time === 'minute') {
        second =
          (sendTime.hour * 3600 + inputTime * 60 + sendTime.second) *
          (isPlus ? 1 : -1);
      } else {
        second =
          (sendTime.hour * 3600 + sendTime.minute * 60 + inputTime) *
          (isPlus ? 1 : -1);
      }

      setSecond(second);
    },
    [isPlus, sendTime],
  );

  const onTimeClicked = useCallback((time: number) => {
    setSecond((prev) => prev + time * 60);
  }, []);

  const onSignToggleClicked = useCallback(() => {
    setSecond((prev) => -prev);
  }, []);

  const onBoardCheckClicked = useCallback(
    (index: number) => {
      let chaged: BoardItemStateType[] = Object.assign([], stateList);
      if (chaged[index] === 'checked') {
        chaged[index] = 'unchecked';
      } else if (chaged[index] === 'unchecked') {
        chaged[index] = 'checked';
      }
      setStateList(chaged);
    },
    [stateList],
  );

  const onBoardLockCliked = useCallback(
    async (index: number) => {
      let user = userList[index];
      let chaged: BoardItemStateType[] = Object.assign([], stateList);

      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.dashboard.lock + `?id=${user.id}&isLocked=${!user.isLocked}`,
        {},
        {},
        getAccessToken() ?? '',
      );
      if (chaged[index] === 'disabled') {
        chaged[index] = 'unchecked';
      } else {
        chaged[index] = 'disabled';
      }
      setStateList(chaged);
    },
    [stateList, userList],
  );

  const getUserList = useCallback(async () => {
    const {
      data: { rows },
      config: { status },
    } = await requestSecureGet<BasicListDataType<UserType>>(
      apiRoute.dashboard.getUserList + '?page=0&perPage=12',
      {},
      getAccessToken() ?? '',
    );

    if (status !== 200) {
      return;
    }

    setUserList(rows);
    setStateList((prev) =>
      rows.map((user, index) => {
        if (user.isLocked) {
          return 'disabled';
        } else {
          return prev[index];
        }
      }),
    );
  }, []);

  const onSendClicked = useCallback(() => {
    if (second === 0) {
      alert('0시간은 보낼 수 없습니다');
      return;
    }
    if (!stateList.includes('checked')) {
      alert('아무도 선택되지 않았습니다');
      return;
    }
    Promise.all(
      stateList.map((state, index) => {
        if (state === 'checked') {
          return requestSecurePatch<{}>(
            apiRoute.dashboard.time +
              `?id=${userList[index].id}&time=${second}`,
            {},
            {},
            getAccessToken() || '',
          );
        }
      }),
    ).then((_) => {
      setSecond(0);
      if (systemState === 'shutdown') {
        getUserList();
      }
    });
  }, [userList, second, stateList, systemState, getUserList]);

  const getSystemStatus = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet(
      apiRoute.dashboard.status,
      {},
      getAccessToken() || '',
    );

    if (status !== 200) {
      return;
    }

    if (data) {
      __startSystem();
    } else {
      __shutdownSystem();
    }
  }, [__startSystem, __shutdownSystem]);

  //시작 버튼
  const onStartButtonClicked = useCallback(async () => {
    showPopup(<StartPopupContainer />);
  }, [showPopup, StartPopupContainer]);

  //정지버튼
  const onEndButtonClicked = useCallback(async () => {
    showPopup(<StopPopupContainer />);
  }, [showPopup, StopPopupContainer]);

  const onResetButotnClicked = useCallback(() => {
    showPopup(<EndPopupContainer getUserList={getUserList} />);
  }, [showPopup, EndPopupContainer, getUserList]);

  useEffect(() => {
    getUserList();
    getSystemStatus();
    __initializeSortedIndex();
  }, []);

  useEffect(() => {
    if (systemState === 'processing') {
      let timeout = setTimeout(getUserList, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [userList, getUserList, systemState]);

  useEffect(() => {
    let absoluteSecond = Math.abs(second);
    setSendTime({
      hour: Math.floor(absoluteSecond / 3600),
      minute: Math.floor((absoluteSecond % 3600) / 60),
      second: absoluteSecond % 60,
    });
  }, [second]);

  return (
    <Board
      onEntireClicked={onEntireClicked}
      onTimeChanged={onTimeChanged}
      onTimeClicked={onTimeClicked}
      onResetClicked={onResetClicked}
      onSendClicked={onSendClicked}
      onBoardCheckClicked={onBoardCheckClicked}
      onBoardLockCliked={onBoardLockCliked}
      onSignToggleClicked={onSignToggleClicked}
      onStartButtonClicked={onStartButtonClicked}
      onEndButtonClicked={onEndButtonClicked}
      onResetButtonClicked={onResetButotnClicked}
      sortedIndex={sortedIndex}
      userList={userList}
      sendTime={sendTime}
      stateList={stateList}
      isPlus={isPlus}
      systemState={systemState}
      boardRef={boardRef}
    />
  );
};

export default BoardContainer;
