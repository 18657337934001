import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import axios from 'axios';

export type ErrorResponseType = {
  message: string;
  statusCode: number;
};

export const API_ORIGIN =
  process.env.NODE_ENV === 'development'
    ? 'https://the-time-hotel-api.wimcorp.dev'
    : 'https://the-time-hotel-api.wimcorp.dev';

const REFRESH_STATUS = 1000;

axios.defaults.baseURL = `${API_ORIGIN}/api/v1`;

axios.interceptors.request.use((req) => {
  if (process.env.NODE_ENV === 'development') {
  }

  return req;
});

axios.interceptors.response.use(
  (res) => res,
  // remark : refresh 토큰 요청 오류로 주석 처리함
  // async (e) => {
  //   const status = e?.response?.status;
  //   if (status === 401) {
  //     const {
  //       getAccessToken,
  //       getRefreshToken,
  //       setAccessToken,
  //       setRefreshToken,
  //       claerRefreshToken,
  //       clearAccessToken,
  //     } = useToken();
  //     const { setRootRoute } = useRootRoute();

  //     if (!getRefreshToken()) {
  //       setRootRoute('login');
  //       return;
  //     }

  //     const {
  //       data,
  //       config: { status },
  //     } = await requestSecurePost<{
  //       accessToken: string;
  //       refreshToken: string;
  //     }>(apiRoute.auth.refresh, {}, {}, getRefreshToken()!);

  //     if (status === 200) {
  //       setAccessToken(data.accessToken);
  //       setRefreshToken(data.refreshToken);
  //       setRootRoute('main');
  //       return {
  //         ...e,
  //         status: REFRESH_STATUS,
  //         data: { accessToken: data.accessToken },
  //       };
  //     }

  //     if (status === 401) {
  //       claerRefreshToken();
  //       clearAccessToken();
  //       setRootRoute('login');
  //       return;
  //     }
  //   }
  // },
);

export const apiRoute = {
  auth: {
    adminLogin: '/login-admin',
    logout: '/logout',
    refresh: '/refresh',
  },
  send: {
    getList: '/admins/list',
    allow: '/admins/user/send/allow',
  },
  total: {
    getTotalList: '/admins/list/usage',
  },
  dashboard: {
    time: '/admins/user/time',
    getUserList: '/users/list',
    lock: '/users/lock',
    end: 'admins/end',
    stop: 'admins/end/noremove',
    start: 'admins/start',
    status: 'admins/status',
    name: 'admins/user/name',
  },
  bank: {
    time: '/admins/user/bank',
    interest: '/admins/interest',
  },
  buy: { getList: '/admins/list/buy' },
  livetime: { updateLivetime: '/admins/user/time/all/live' },
};

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
  };
};

export type BasicListDataType<T> = {
  rows: T[];
  page: number;
  maxPage: number;
};

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then((res) => {
      if (res.status === REFRESH_STATUS) {
        return axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${res.data.accessToken}`,
            ...header,
          },
        });
      } else {
        return res;
      }
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: err.response.data.message,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPost<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPatch<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
