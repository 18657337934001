import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header';

const HeaderContainer = () => {
  const location = useLocation();

  const { clearAccessToken, claerRefreshToken } = useToken();

  const [currentTab, setCurrentTab] = useState(window.location.pathname);

  const onLogoutClicked = useCallback(() => {
    clearAccessToken();
    claerRefreshToken();
    window.location.replace('/login');
  }, []);

  useEffect(() => {
    setCurrentTab(location.pathname);
  }, [location]);

  return <Header currentTab={currentTab} onLogoutClicked={onLogoutClicked} />;
};

export default HeaderContainer;
