import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { amountOfTimeFormat, createAtFormat } from '@lib/factory';
import { SendListItemTypes } from '@typedef/Send/send.types';
import React from 'react';
import './styles/send.styles.css';

type Props = {
  sendList: SendListItemTypes[];
  page: number;
  maxPage: number;
  onPageChanged: (page: number) => void;
};

const Send = ({ sendList, page, maxPage, onPageChanged }: Props) => {
  return (
    <div className='send-root'>
      <div className='table'>
        <div className='table-header'>
          <span className='col' style={{ width: '123px' }}>
            {'보낸 사람'}
          </span>
          <span className='col' style={{ width: '123px' }}>
            {'받은 사람'}
          </span>
          <span className='col' style={{ width: '136px' }}>
            {'조정 시간'}
          </span>
          <span className='col' style={{ width: '260px' }}>
            {'구매 시간'}
          </span>
          <span className='col' style={{ width: '123px' }}>
            {'승인여부'}
          </span>
        </div>
        <div className='table-body'>
          <ul>
            {sendList.map((item, idx) => {
              return (
                <li className='list-item'>
                  <span className='col' style={{ width: '123px' }}>
                    {item.senderId === item.recieveId && item.tradedTime > 0
                      ? '마스터'
                      : item.sendUser.displayName}
                  </span>
                  <span className='col' style={{ width: '123px' }}>
                    {item.senderId === item.recieveId && item.tradedTime < 0
                      ? '마스터'
                      : item.recievedUser.displayName}
                  </span>
                  <span className='col' style={{ width: '136px' }}>
                    {item.tradedTime
                      ? amountOfTimeFormat(item.tradedTime)
                      : '0초'}
                  </span>
                  <span className='col' style={{ width: '260px' }}>
                    {createAtFormat(item.createAt)}
                  </span>
                  <span className='col' style={{ width: '123px' }}>
                    {item.isAllowed === null
                      ? ''
                      : item.isAllowed
                      ? '승인됨'
                      : '거절됨'}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <CPagingContainer
        page={page}
        maxPage={maxPage}
        onPageChanged={onPageChanged}
      />
    </div>
  );
};

export default Send;
