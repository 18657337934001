import { images } from '@assets/images';
import { BoardItemStateType } from '@typedef/Board/board.types';
import React from 'react';

type Props = {
  state: BoardItemStateType;
  user: string;
  redTime: string;
  blueTime: string;
  onCheckClicked: () => void;
  onLockClicked: () => void;
  onNameClicked: () => void;
};

const UserItem = ({
  state,
  user,
  redTime,
  blueTime,
  onCheckClicked,
  onLockClicked,
  onNameClicked,
}: Props) => {
  return (
    <div className={`user-item-root ${state}`}>
      <div className='header'>
        <button
          className='check-box'
          disabled={state === 'disabled'}
          onClick={onCheckClicked}>
          {state === 'checked' && (
            <img src={images.check.selected} alt='ic_selected' />
          )}
          {state === 'unchecked' && (
            <img src={images.check.unselected} alt='ic_unselected' />
          )}
          {state === 'disabled' && (
            <img src={images.check.disselected} alt='ic_disabled' />
          )}
        </button>
        <button className='lock' onClick={onLockClicked}>
          {state === 'disabled' ? (
            <img src={images.lock.close} alt='ic_lock_close' />
          ) : (
            <img src={images.lock.open} alt='ic_lock_open' />
          )}
        </button>
      </div>
      <div className='content'>
        <div className='username' onClick={onNameClicked}>
          {user}
        </div>
        <div className='red-time'>{redTime}</div>
        <div className='blue-time'>{blueTime}</div>
      </div>
    </div>
  );
};

export default UserItem;
