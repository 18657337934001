import useRootRoute from '@hooks/routes/useRootRoute';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotFound from '../NotFound';

const NotFoundContainer = () => {
  const navigate = useNavigate();
  const { getRootRoute, setRootRoute } = useRootRoute();

  const onLoginStateLoad = useCallback(() => {
    const state = getRootRoute();
    if (state === 'main') {
      navigate('/', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    onLoginStateLoad();
  }, []);

  return <NotFound />;
};

export default NotFoundContainer;
