export const images = {
  gnb: {
    gnb01Inatv: "/assets/images/gnb/ic-gnb-01-inatv@3x.png",
    gnb01atv: "/assets/images/gnb/ic-gnb-01-atv@3x.png",
    gnb02Inatv: "/assets/images/gnb/ic-gnb-02-inatv@3x.png",
    gnb02atv: "/assets/images/gnb/ic-gnb-02-atv@3x.png",
    gnb03Inatv: "/assets/images/gnb/ic-gnb-03-inatv@3x.png",
    gnb03atv: "/assets/images/gnb/ic-gnb-03-atv@3x.png",
    gnb04Inatv: "/assets/images/gnb/ic-gnb-04-inatv@3x.png",
    gnb04atv: "/assets/images/gnb/ic-gnb-04-atv@3x.png",
    gnb05Inatv: "/assets/images/gnb/ic-gnb-05-inatv@3x.png",
    gnb05atv: "/assets/images/gnb/ic-gnb-05-atv@3x.png",
    gnb06Inatv: "/assets/images/gnb/ic-gnb-06-inatv@3x.png",
    gnb06atv: "/assets/images/gnb/ic-gnb-06-atv@3x.png",
  },
  cpaging: {
    prev: "/assets/images/ic-arrow-left@3x.png",
    next: "/assets/images/ic-arrow-right@3x.png",
    prevDisabled: "/assets/images/ic-arrow-left-disable@3x.png",
    nextDisabled: "/assets/images/ic-arrow-right-disable@3x.png",
  },
  deleteBtn: "/assets/images/ic-delete.png",
  eyeDisable: "/assets/images/ic-eye-disable.png",
  eye: "/assets/images/ic-eye.png",
  date: "/assets/images/ic-date.png",
  check: {
    selected: "/assets/images/ic-selected.png",
    unselected: "/assets/images/ic-unselected.png",
    disselected: "/assets/images/ic-disselected.png",
  },
  lock: {
    open: "/assets/images/ic-unlock.png",
    close: "/assets/images/ic-locked.png",
  },
};
