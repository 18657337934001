import useToken from '@hooks/store/useToken';
import { apiRoute, BasicListDataType, requestSecureGet } from '@lib/api/api';
import { todayFormat } from '@lib/factory';
import { UserType } from '@typedef/common/user.types';
import { TotalListItemType } from '@typedef/Total/total.types';
import React, { useCallback, useRef, useState } from 'react';
import { RefObject } from 'react';
import { useEffect } from 'react';
import Total from '../Total';

const TotalContainer = () => {
  const { getAccessToken } = useToken();
  const [date, setDate] = useState(todayFormat());
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [totalList, setTotalList] = useState<TotalListItemType[]>([]);
  const datepickerRef = useRef<HTMLInputElement>(null);

  const onPageChanged = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onDateChanged = useCallback((date: string) => {
    setDate(date);
  }, []);

  const onUserListLoad = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<TotalListItemType>>(
      apiRoute.total.getTotalList + `?page=${page}&perPage=12&createAt=${date}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alert('유저 정보 조회에 실패했습니다.');
      return;
    }

    setTotalList(data.rows);
    setMaxPage(data.maxPage);
  }, [page, date]);

  useEffect(() => {
    onUserListLoad();
  }, [onUserListLoad]);

  return totalList ? (
    <Total
      page={page}
      date={date}
      maxPage={maxPage}
      datepickerRef={datepickerRef}
      onPageChanged={onPageChanged}
      onDateChanged={onDateChanged}
      totalList={totalList}
    />
  ) : (
    <></>
  );
};

export default TotalContainer;
